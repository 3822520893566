import React, { lazy, useEffect } from 'react';
import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';

import './App.scss';

// Layouts
import Nav from './layouts/Nav.js';
// Pages
const MainPage = lazy(() => import('./pages/MainPage.js'));
const Speisekarte = lazy(() => import('./pages/Speisekarte.js'));
const Impressum = lazy(() => import('./pages/Impressum.js'));
const Datenschutz = lazy(() => import('./pages/Datenschutz.js'));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Nav />}>
      <Route index element={<MainPage />} />
      <Route path="speisekarte" element={<Speisekarte />} />
      <Route path="impressum" element={<Impressum />} />
      <Route path="datenschutz" element={<Datenschutz />} />
    </Route>
  )
);

function App() {
  useEffect(() => {
    document.cookie = 'SameSite=None; Secure';
  }, []);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
