import React from 'react';
import { NavLink } from 'react-router-dom';

function Modal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>
          x
        </button>
        <NavLink to="datenschutz" className="modal-link">
          Datenschutz
        </NavLink>
        <NavLink to="impressum" className="modal-link">
          Impressum
        </NavLink>
      </div>
    </div>
  );
}

export default Modal;
