import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    const hasConsent = localStorage.getItem('cookieConsent');
    if (hasConsent) {
      setShowBanner(false);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');

    setShowBanner(false);
  };

  return (
    showBanner && (
      <div className="cookie-banner">
        <p>
          Diese Webseite verwendet Cookies 🍪, mehr dazu unter
          <Link to="datenschutz" className="link-datenschutz">
            Datenschutz
          </Link>
          .
        </p>
        <button onClick={handleAccept} className="cookie-button">
          OK
        </button>
      </div>
    )
  );
};

export default CookieBanner;
